import * as t from "io-ts";

import { AlignedRichText } from "./aligned-rich-text";

export const Page = t.interface({
    id: t.number,
    url: t.string,
});

export const TimedEmailContent = t.interface({
    content_text: AlignedRichText,
    confirm_content: AlignedRichText,
    close_text: t.string,
    footnotes: AlignedRichText,
    pages: t.array(Page),
});

export const TimedEmail = t.interface({
    title: t.string,
    email_content: t.array(TimedEmailContent),
    timing: t.string,
});
