import React from "react";
import { Link } from "tsi-common-react/src/common/Link";
import { FinancingModalTriggerTheme } from "tsi-common-react/src/constants";
import { format } from "tsi-common-react/src/utils/format";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

interface IProps {
    theme?: FinancingModalTriggerTheme;
    termAPR: string;
    termMonths: number;
    termThreshold: string;
    aprInfoID: string;
    promoPeriod: string;
}

export const UnifiModalTriggerBlockCopy = (props: IProps) => {
    if (props.theme === FinancingModalTriggerTheme.SPECIAL) {
        return (
            <div>
                <h2>{t`Special Financing Offers Available`}</h2>
                <p>
                    {t`Buy today and pay over time. Subject to credit approval`}
                </p>
            </div>
        );
    }

    const financeLink = urls.pageURL("finance-link");
    const apr = parseInt(props.termAPR, 10);
    const termYears = props.termMonths / 12;
    if (
        props.theme === FinancingModalTriggerTheme.MINI_FINANCING ||
        props.theme === FinancingModalTriggerTheme.CARD_OVERLAP
    ) {
        const useMiniFinancingText =
            props.theme === FinancingModalTriggerTheme.MINI_FINANCING;
        const offerCopy = useMiniFinancingText
            ? t`${apr}% APR for ${props.termMonths} Months`
            : t`${termYears} Years - ${apr}% interest`;

        const termThreshold = format.money(props.termThreshold);
        const subHeadline = useMiniFinancingText
            ? ""
            : t`On all purchases of ${termThreshold} or more made with your Sleep Outfitters credit card from ${props.promoPeriod}. Equal monthly payments required for ${props.termMonths} months. Promo fee of 2% of amount financed will be included in required monthly payments.¹`;

        const subCopy = useMiniFinancingText
            ? t`Check if you pre-qualify without affecting your credit score.`
            : t`Get a decision in seconds with no impact to your credit bureau score.`;
        return (
            <div>
                <h2>
                    {offerCopy}
                    <sup>
                        <a href={props.aprInfoID}>1</a>
                    </sup>
                </h2>
                <p>{subHeadline}</p>
                <p>{subCopy}</p>
            </div>
        );
    }
    return (
        <>
            <div className="pre-approval-ad__terms">
                <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--1">
                    {t`${apr}% APR`}
                </div>
                <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--2">
                    {t`for`} <div className="pre-approval-ad__line-rule"></div>
                </div>
                <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--3">
                    {t`${props.termMonths} Months`}
                    <sup>
                        <Link href={financeLink}>
                            <span className="ada-screenreader-only">
                                {t`Additional information about Terms and Conditions`}
                            </span>
                        </Link>
                    </sup>
                </div>
            </div>
            <div className="pre-approval-ad__see pre-approval-ad__see--block">
                <div className="pre-approval-ad__see--bold">
                    {t`Check if you're pre-qualified`}
                </div>
                <div className="pre-approval-ad__see--small">
                    {t`without affecting your credit score.`}
                </div>
            </div>
        </>
    );
};
