import classNames from "classnames";
import React from "react";
import sleepoutfittersCc from "tsi-common-react/img/finance/sleepoutfitters-cc.png";
import { FinancingModalTriggerTheme } from "tsi-common-react/src/constants";
import { t } from "ttag";

import { UnifiModalTrigger } from "./UnifiModalTrigger";
import { UnifiModalTriggerBlockContent } from "./UnifiModalTriggerBlockContent";

interface IProps {
    theme?: FinancingModalTriggerTheme;
    termAPR: string;
    termMonths: number;
    termThreshold: string;
    aprInfoID: string;
    promoPeriod: string;
}

export const UnifiModalTriggerBlock = (props: IProps) => {
    const prequalifyClassesMobile = classNames({
        "button": true,
        "pre-approval-ad__button": true,
        "pre-approval-ad__button--block": true,
        "pre-approval-ad__button--check": true,
        "al-homepage__pre-approval-ad--pre-qualify": true,
        "al-homepage__pre-approval-ad--pre-qualify-mobile": true,
    });
    const iconClasses = classNames({
        "pre-approval-ad__icon": true,
        "pre-approval-ad__icon--block": true,
        "pre-approval-ad__icon--card": true,
    });

    return (
        <div className="pre-approval-ad__block-container">
            <div className="pre-approval-ad pre-approval-ad--block l-capped-width u-flex-container">
                <img
                    className={iconClasses}
                    alt={t`Credit Card image`}
                    src={sleepoutfittersCc}
                />
                {props.theme === FinancingModalTriggerTheme.CARD_OVERLAP ||
                props.theme === FinancingModalTriggerTheme.SPECIAL ? (
                    <div>
                        <UnifiModalTriggerBlockContent {...props} />
                    </div>
                ) : (
                    <UnifiModalTriggerBlockContent {...props} />
                )}
            </div>
            <div className="block__pre-approval-ad--mobile pre-approval-ad u-flex-container">
                <div className="pre-approval-ad__see pre-approval-ad__see--block-mobile">
                    <div className="pre-approval-ad__see--bold">
                        {t`Check if you pre-qualify`}
                    </div>
                    <div className="pre-approval-ad__see--small">
                        {t`without affecting your credit score.`}
                    </div>
                </div>
                <UnifiModalTrigger
                    className={prequalifyClassesMobile}
                    termThreshold={props.termThreshold}
                >
                    {t`Check Now`}
                </UnifiModalTrigger>
            </div>
        </div>
    );
};
