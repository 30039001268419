import classNames from "classnames";
import React from "react";
import Flair from "tsi-common-react/src/common/Flair";
import { Image } from "tsi-common-react/src/common/Image";
import Link from "tsi-common-react/src/common/Link";
import urls from "tsi-common-react/src/utils/urls";

import { IInStoreOffers } from "../models";

import styles from "./InStoreOffers.module.scss";

interface IProps {
    offers: IInStoreOffers | null;
}

interface IState {
    isExpanded: boolean;
}

export class InStoreOffers extends React.Component<IProps, IState> {
    public state: IState = {
        isExpanded: true,
    };

    private readonly toggleExpanded = () => {
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    };

    render() {
        if (!this.props.offers) {
            return;
        }

        const headerClasses = classNames({
            [styles.header]: true,
            [styles.headerClosed]: !this.state.isExpanded,
        });

        return (
            <div className={styles.root}>
                <p
                    className={headerClasses}
                    aria-expanded={this.state.isExpanded}
                    onClick={this.toggleExpanded}
                >
                    In-Store Offers
                </p>
                {this.state.isExpanded && (
                    <div className={styles.offersContainer}>
                        <div className={styles.offers}>
                            {this.props.offers.map((offer, index) => (
                                <div className={styles.offer} key={index}>
                                    {offer.image && (
                                        <span className={styles.image}>
                                            <Image
                                                src={offer.image?.url}
                                                alt={offer.image.title}
                                            />
                                        </span>
                                    )}
                                    <div className={styles.textAndFlair}>
                                        <div
                                            className={styles.offerText}
                                            dangerouslySetInnerHTML={{
                                                __html: offer.text,
                                            }}
                                        ></div>
                                        {offer.flair && (
                                            <Flair flair={offer.flair} />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Link target="_blank" href={urls.pageURL("offers")}>
                            See All Offers
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}
