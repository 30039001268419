import React from "react";
import { Provider } from "react-redux";
import { registerCascades } from "tsi-common-react/src/apps/retail/cascades";
import { check } from "tsi-common-react/src/models/utils";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

import { store } from "../store";
import { loadClosestStores } from "./loaders";
import { LocationsColumnBlockCMSData } from "./models";

// Register store cascades
registerCascades(store, loadClosestStores);

dynamicPlaceComponent(
    '[data-place-react="locations-column-block"]',
    async (elem) => {
        const { LocationsColumnBlock } = await import(
            "./containers/LocationsColumnBlock"
        );
        const cmsData = check(
            LocationsColumnBlockCMSData.decode(
                JSON.parse(elem.dataset.cms || ""),
            ),
        );
        return (
            <Provider store={store}>
                <LocationsColumnBlock withMap={cmsData.with_map} />
            </Provider>
        );
    },
).catch(console.error);
