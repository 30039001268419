import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { buildBaseQuery } from "tsi-common-react/src/apps/retail/loaders";
import { ILocation } from "tsi-common-react/src/models/location.interfaces";
import { IWagtailPageID } from "tsi-common-react/src/models/nominals";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

import {
    RetailLocationPage,
    convertStorePageShape,
    getRetailLocationPageByID,
} from "../../api/retail";
import { StoreInfo } from "./StoreInfo";

interface IOwnProps {
    pageID: IWagtailPageID;
}

interface IReduxProps {
    location: ILocation | null;
}

interface IProps extends IOwnProps, IReduxProps {}

const IndividualStoreInfoComponent = (props: IProps) => {
    const [storePage, setStorePage] = useState<RetailLocationPage | null>(null);
    const [prevLocation, setPrevLocation] = useState<ILocation | null>(null);

    const fetchStore = async (
        pageID: IWagtailPageID,
        location: ILocation | null,
    ) => {
        const query = location ? buildBaseQuery(location) : null;
        const _storePage = await getRetailLocationPageByID(pageID, query);
        setStorePage(_storePage);
        setPrevLocation(location);
    };

    // Load the store data is it either doesn't exist, if the pageID prop has
    // changed, or if the current location has changed.
    useEffect(() => {
        if (
            storePage?.id !== props.pageID ||
            JSON.stringify(props.location) !== JSON.stringify(prevLocation)
        ) {
            fetchStore(props.pageID, props.location);
        }
    }, [storePage, props.pageID, props.location]);

    if (!storePage) {
        return null;
    }

    return (
        <StoreInfo
            store={convertStorePageShape(storePage)}
            showReviews={true}
            showBookApptLink={
                getPageSetting("app-slug") !== "sleepoutfittersoutlet"
            }
        />
    );
};

const mapStateToProps: TStateMapper<"common", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        location: preferredLocationSelector(state),
        ...ownProps,
    };
};

export const IndividualStoreInfo = connect(mapStateToProps)(
    IndividualStoreInfoComponent,
);
