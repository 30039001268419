import * as t from "io-ts";

import { SafeHTML } from "./nominals";
import {
    BackgroundStylesSubBlock,
    SpacingSubBlock,
} from "./streamfield-blocks";
import { nullable } from "./utils";

export const AlignedRichText = t.interface({
    alignment: t.string,
    background_style: BackgroundStylesSubBlock,
    content: nullable(SafeHTML),
    full_width: t.boolean,
    spacing: SpacingSubBlock,
});
