import { loadClosestStores } from "@client/src/RetailLocator/loaders";
import React from "react";
import { Provider } from "react-redux";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { registerCascades } from "tsi-common-react/src/apps/retail/cascades";
import { Dispatchers } from "tsi-common-react/src/apps/retail/dispatchers";
import { check } from "tsi-common-react/src/models/utils";
import {
    closeStoreLocatorPanel,
    isStoreLocatorPanelOpen,
    openStoreLocatorPanel,
} from "tsi-common-react/src/utils/domManipulation";
import {
    onDOMContentLoaded,
    readyStateComplete,
} from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

import { rehydratingStore, store } from "../store";
import { CurrentStore } from "./components/CurrentStore";
import { InStoreOffersCMSData } from "./models";

registerCascades(store, loadClosestStores);
const dispatchers = new Dispatchers(store.dispatch);
const STORE_LOCATOR_PANEL_KEY = "storeLocatorPanelInit";

dynamicPlaceComponent(
    '[data-place-react="store-locator-panel"]',
    async (elem) => {
        // Wait for the document to load, this needs to render multi product options properly
        await readyStateComplete;
        await rehydratingStore;

        const { StoreLocatorPanel } = await import(
            "./containers/StoreLocatorPanel"
        );
        const cmsData = check(
            InStoreOffersCMSData.decode(JSON.parse(elem.dataset.offers || "")),
        );
        const location = preferredLocationSelector(store.getState());
        if (location) {
            const stores = await loadClosestStores(location);
            if (stores) {
                dispatchers.setStores(STORE_LOCATOR_PANEL_KEY, stores);
            }
        }
        return (
            <Provider store={store}>
                <StoreLocatorPanel offers={cmsData.offers} />
            </Provider>
        );
    },
).catch(console.error);

// Render Rich Navigation Current Store Component
dynamicPlaceComponent(
    '[data-place-react="current-store-section"]',
    async () => {
        return (
            <Provider store={store}>
                <CurrentStore />
            </Provider>
        );
    },
).catch(console.error);

const registerEventListeners_PanelTrigger = () => {
    const storeLocatorPanelTrigger = document.getElementById(
        "store-locator-panel-trigger",
    );
    if (storeLocatorPanelTrigger) {
        storeLocatorPanelTrigger.addEventListener("click", (event) => {
            event.preventDefault();
            if (isStoreLocatorPanelOpen()) {
                closeStoreLocatorPanel();
                return;
            }
            openStoreLocatorPanel();
        });
    }
};

onDOMContentLoaded.on(() => {
    registerEventListeners_PanelTrigger();
});
