import React from "react";
import SVG from "react-inlinesvg";
import { CartReferenceNumber } from "tsi-common-react/src/apps/checkout/CartReferenceNumber";
import { t } from "ttag";

import { templates } from "@reactivated";

import logo from "../src/svg/so-logo.svg";

export const Template = (
    props: templates.CheckoutHeaderTemplate,
): React.ReactNode => {
    return (
        <header className="l-full-width">
            <nav className="checkout-header">
                <div className="l-capped-width">
                    <a
                        href={props.root_page_url}
                        title={t`Sleep Outfitters Home`}
                    >
                        <SVG src={logo} title={t`Sleep Outfitters Home`} />
                    </a>

                    <h1>{t`secure checkout`}</h1>

                    <div className="checkout-header__header-cart-ref">
                        <CartReferenceNumber
                            encodedBasketID={props.encoded_basket_id}
                            phoneNumber={props.support_website_phone_number}
                        />
                    </div>
                </div>
            </nav>
        </header>
    );
};
