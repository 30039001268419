import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import { IAPIPrice } from "../../models/prices.interfaces";
import { defaults } from "../configurator/defaults";
import { upgradedVariantPriceSelector } from "../configurator/selectors";
import { TStateMapper } from "../reducers.interfaces";
import { UnifyMerchantLibrary } from "./UnifyMerchantLibrary";

interface IOwnProps {}

interface IReduxProps {
    price: IAPIPrice | null;
}

type IProps = IOwnProps & IReduxProps;

export const UnifyPDPWidgetComponent = (props: IProps) => {
    // We normally try to avoid using IDs in components, but the Unify merchant
    // library specifically looks for this element ID to determine the product
    // price.
    const productPriceID = "product-content";
    const productPrice = useRef<HTMLDivElement>(null);

    // Since we have to use an element ID, and IDs must be unique, log an error
    // if we try to render this component more than once on a page.
    useEffect(() => {
        const elems = document.querySelectorAll(`#${productPriceID}`);
        if (elems.length > 1) {
            console.warn(
                "Don't render UnifyPDPWidget more than once per page! It won't work properly.",
                elems,
            );
        }
    });

    // TODO: There seems to be a bug in the Unify library where, when the
    // product price changes, the monthly price that unify displays doesn't
    // update. Thus, the monthly price will always reflect the product price
    // that was set when this component was first rendered.
    return (
        <>
            <UnifyMerchantLibrary />
            <div
                id={productPriceID}
                ref={productPrice}
                style={{ display: "none" }}
            >
                {props.price ? props.price.unit.cosmetic_excl_tax : ""}
            </div>
            <div className="sync-price"></div>
        </>
    );
};

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        price: upgradedVariantPriceSelector(rootState.configurator || defaults),
        ...ownProps,
    };
};

export const UnifyPDPWidget = connect(mapStateToProps)(UnifyPDPWidgetComponent);
